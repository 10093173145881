import axios from 'axios'
const baseUrl = 'https://dev.api.invictusworks.ca/khan/personas'

const getAll = () => {
    const request = axios.get(baseUrl)
    const response = request.then(response => response.data);
    console.log(response);
    return response;
}

const getPersona = (isbn) => {
    const request = axios.get(`${baseUrl}/${isbn}`)
    return request.then(response => response.data)
}

const remove = (isbn) => {
    const request = axios.delete(`${baseUrl}/${isbn}`)
    return request
}

const create = (newObject) => {
    const request = axios.post(baseUrl, newObject)
    return request.then(response => response.data)
}

const update = (isbn, newObject) => {
    const request = axios.put(`${baseUrl}/${isbn}`, newObject)
    return request.then(response => response.data)
}


const personasService = {
    getAll,
    getPersona,
    remove,
    create,
    update
}

export default personasService