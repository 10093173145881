import React, { useState } from 'react'
import './App.less'
import Persona from './components/Screens/Persona'
import CreateNewPersona from './components/Screens/CreateNewPersona'
import EditPersona from './components/Screens/EditPersona'
import PersonaTable from './components/Screens/PersonaTable'
import {
    BrowserRouter as Router,
    Switch, Route,
} from 'react-router-dom'

// Routing to the different screens

const App = () => {
    const [personas, setPersonas] = useState([])
    return (
        <Router>
            <Switch>
                <Route exact path='/'>
                    <PersonaTable personas={personas} setPersonas={setPersonas}/>
                </Route>
                <Route exact path='/personas'>
                    <PersonaTable personas={personas} setPersonas={setPersonas}/>
                </Route>
                <Route exact path='/personas/create'>
                    <CreateNewPersona personas={personas} setPersonas={setPersonas}/>
                </Route>
                <Route exact path='/personas/edit/:id'>
                    <EditPersona />
                </Route>
                <Route exact path='/personas/:id'>
                    <Persona personas={personas} setPersonas={setPersonas}/>
                </Route>
            </Switch>
        </Router>
    )
}

export default App;
