import React, {useState, useEffect} from 'react'
import {
    useParams, useHistory
} from 'react-router-dom'

import personasService from '../../services/personasService';
import { Form, Button, Input} from 'antd';
import { PageHeader } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

const EditPersona = () => {
    const history = useHistory()
    const id = useParams().id
    const [newFirstName, setNewFirstName] = useState('')
    const [newLastName, setNewLastName] = useState('')
    const [newEmail, setNewEmail] = useState('')
    const [newPhoneNumber, setNewPhoneNumber] = useState('')
    const [newTitle, setNewTitle] = useState('')
    const [newAbout, setNewAbout] = useState('')
    useEffect(() => {
        personasService
            .getPersona(id)
            .then(persona => {
                setNewFirstName(persona.firstName)
                setNewLastName(persona.lastName)
                setNewEmail(persona.email)
                setNewPhoneNumber(persona.phoneNumber)
                setNewTitle(persona.title)
                setNewAbout(persona.about)
            })
    }, [id])

    const updatePersona = (values) => {
        const PersonaObject = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phoneNumber,
            title: values.title,
            about: values.about
        }
        personasService
            .update(id, PersonaObject)
            .then(returnedPersona => {
            })
    }

    return (
        <div>
            <PageHeader
                onBack={() => history.goBack()}
                title='Editing' 
                subTitle={newTitle}
            />
            <Form
                style={{padding: '2%'}}
                onFinish={(values) => {
                    updatePersona(values)
                    history.goBack()
                }}
                autoComplete='off'
                labelCol={{ span: 3 }}
                // having fields so that form is already filled up by exisiting data
                fields={[
                    {
                        name: ['First Name'],
                        value: newFirstName
                    },
                    {
                        name: ['Last Name'],
                        value: newLastName
                    },
                    {
                        name: ['Email'],
                        value: newEmail
                    },
                    {
                        name: ['Phone Number'],
                        value: newPhoneNumber
                    },
                    {
                        name: ['Title'],
                        value: newTitle
                    },
                    {
                        name: ['About'],
                        value: newAbout
                    }
                ]}
            >
                <Form.Item 
                    name='firstName' 
                    label='First Name'
                    rules= {[
                        { whitespace: true }
                    ]}
                    hasFeedback
                >
                    <Input placeholder='First Name' />
                </Form.Item>
                <Form.Item 
                    name='firstName' 
                    label='First Name'
                    rules= {[
                        { whitespace: true }
                    ]}
                    hasFeedback
                >
                    <Input placeholder='Last Name' />
                </Form.Item>
                <Form.Item 
                    name='lastName' 
                    label='Last Name'
                    rules= {[
                        { whitespace: true }
                    ]}
                    hasFeedback
                >
                    <Input placeholder='Last Name' />
                </Form.Item>
                <Form.Item 
                    name='email' 
                    label='Email'
                    rules= {[
                        { whitespace: true }
                    ]}
                    hasFeedback
                >
                    <Input placeholder='Email' />
                </Form.Item>
                <Form.Item 
                    name='phoneNumber' 
                    label='Phone Number'
                    rules= {[
                        { whitespace: true }
                    ]}
                    hasFeedback
                >
                    <Input placeholder='Phone Number' />
                </Form.Item>
                <Form.Item 
                    name='title' 
                    label='Title'
                    rules= {[
                        { whitespace: true }
                    ]}
                    hasFeedback
                >
                    <Input placeholder='Title' />
                </Form.Item>
                <Form.Item 
                    name='about' 
                    label='About'
                    rules= {[
                        { whitespace: true }
                    ]}
                    hasFeedback
                >
                    <Input placeholder='About' />
                </Form.Item>

                <Form.Item>
                    <Button block type='primary' htmlType='submit'>
                        Save Changes
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default EditPersona