import React from 'react'
import personasService from '../../services/personasService';
import {useHistory} from 'react-router-dom'
import { Form, Button, Input} from 'antd';
import { PageHeader } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

const CreateNewPersona = ({personas, setPersonas}) => {
    const history = useHistory()
    const personasIds = personas.map(persona => persona.id)
    const addPersona = (values) => {
        const PersonaObject = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phoneNumber,
            title: values.title,
            about: values.about
        }
        personasService
            .create(PersonaObject)
            .then(newPersona => {
            })
    }
    return (
        <div>
            <PageHeader
                onBack={() => history.goBack()}
                title='Creating New Persona' 
            />
            <Form
                style={{padding: '2%'}}
                onFinish={(values) => {
                    addPersona(values)
                    history.goBack()
                }}
                autoComplete='off'
                labelCol={{ span: 3 }}
            >
                <Form.Item 
                    name='firstName' 
                    label='First Name'
                    rules= {[
                        { whitespace: true }
                    ]}
                    hasFeedback
                >
                    <Input placeholder='First Name' />
                </Form.Item>
                <Form.Item 
                    name='firstName' 
                    label='First Name'
                    rules= {[
                        { whitespace: true }
                    ]}
                    hasFeedback
                >
                    <Input placeholder='Last Name' />
                </Form.Item>
                <Form.Item 
                    name='lastName' 
                    label='Last Name'
                    rules= {[
                        { whitespace: true }
                    ]}
                    hasFeedback
                >
                    <Input placeholder='Last Name' />
                </Form.Item>
                <Form.Item 
                    name='email' 
                    label='Email'
                    rules= {[
                        { whitespace: true }
                    ]}
                    hasFeedback
                >
                    <Input placeholder='Email' />
                </Form.Item>
                <Form.Item 
                    name='phoneNumber' 
                    label='Phone Number'
                    rules= {[
                        { whitespace: true }
                    ]}
                    hasFeedback
                >
                    <Input placeholder='Phone Number' />
                </Form.Item>
                <Form.Item 
                    name='title' 
                    label='Title'
                    rules= {[
                        { whitespace: true }
                    ]}
                    hasFeedback
                >
                    <Input placeholder='Title' />
                </Form.Item>
                <Form.Item 
                    name='about' 
                    label='About'
                    rules= {[
                        { whitespace: true }
                    ]}
                    hasFeedback
                >
                    <Input placeholder='About' />
                </Form.Item>

                <Form.Item >
                    <Button block type='primary' htmlType='submit'>
                        Add New
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default CreateNewPersona