import React, { useEffect } from 'react'
import personasService from '../../services/personasService';
import {useParams, useHistory} from 'react-router-dom'
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { Descriptions } from 'antd';
import { PageHeader } from 'antd';

const Persona = ({personas, setPersonas}) => {
    const history = useHistory()
    useEffect(() => {
        personasService
            .getAll()
            .then(personas => {
                setPersonas(personas)
            })
    }, [setPersonas])
    // obtaining isbn to get specific book
    const id = useParams().id
    const persona = personas.find(persona => persona.id === id)
    // conditional statement to prevent error since book is undefined before useEffect fires
    if (persona !== undefined) {
        return (
            <div>
                <PageHeader 
                    onBack={() => history.goBack()}
                    title='Personal Information' 
                />
                <Descriptions 
                    style={{padding: '2%'}}
                    title={persona.id}
                    bordered
                    column={{ sm: 1, xs: 1 }}
                >
                    <DescriptionsItem label='First Name'>
                        {persona.firstName}
                    </DescriptionsItem>
                    <DescriptionsItem label='Last Name'>
                        {persona.lastName}
                    </DescriptionsItem>
                    <DescriptionsItem label='Email'>
                        {persona.email}
                    </DescriptionsItem>
                    <DescriptionsItem label='Phone Number'>
                        {persona.phoneNumber}
                    </DescriptionsItem>
                    <DescriptionsItem label='Title'>
                        {persona.title}
                    </DescriptionsItem>
                    <DescriptionsItem label='About'>
                        {persona.about}
                    </DescriptionsItem>
                </Descriptions>

            </div>
        )
    }
    return null;
}

export default Persona