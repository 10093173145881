import { Input, Table, Space } from 'antd'
import React, { useEffect } from 'react'
import EditPersonaButton from '../Buttons/EditPersonaButton'
import CreatePersonaButton from '../Buttons/CreatePersonaButton'
import DeletePersonaButton from '../Buttons/DeletePersonaButton'
import ViewMoreButton from '../Buttons/ViewMoreButton'
import { SearchOutlined } from '@ant-design/icons'
import personasService from '../../services/personasService'

const PersonaTable = ({personas, setPersonas}) => {
    useEffect(() => {
        personasService
            .getAll()
            .then(personas => {
                setPersonas(personas)
            })
    }, [setPersonas])
    
    // header for table + able to filter data using filterDropdown
    const columns = [ 
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName'
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'About',
            dataIndex: 'about',
            key: 'about'
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record, index) => (
                <Space>
                    {<DeletePersonaButton personas={personas} setPersonas={setPersonas} id={record.id} />}
                    {<EditPersonaButton persona={record}/>}
                </Space> 
            )
        }
    ]

    // data to fill up the rows of the table
    const data = personas.map(persona => {
        return (
            {
                id: persona.id,
                firstName: persona.firstName,
                lastName: persona.lastName,
                email: persona.email,
                phoneNumber: persona.phoneNumber,
                title: persona.title,
                about: persona.about
            }
        )
    })

    return (
        <div>
            <div style={{display: 'flex'}}>
                <h2 style={{paddingLeft: '1.5%', paddingTop: '1%'}}>
                    Personas Table
                </h2>
                <div style={{ marginLeft: 'auto', paddingRight: '1.5%', paddingTop: '1%' }}>
                    {<CreatePersonaButton />}
                </div>
            </div>
            <Table 
                style={{padding: '1%'}}
                dataSource={data} 
                columns={columns} 
            />
        </div>
    )
}

export default PersonaTable