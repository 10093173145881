import { Button } from 'antd'
import React from 'react'
import personasService from '../../services/personasService'

const DeletePersonaButton = ({id, setPersonas, personas}) => {

    const deletePersona = (id) => {
        personasService
            .remove(id)
            .then(response => {
                setPersonas(personas.filter(persona => persona.id !== id))
            })
    }

    return (
        <Button type='primary' onClick={() => deletePersona(id)}>
            Delete
        </Button>
    )
}

export default DeletePersonaButton